<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>
        <span v-if="query && getentreprise"> {{ getentreprise.lib }}/</span>
        {{ $t("CLIENTS") }}
      </h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore('clientStoreModal')"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}
        {{ $t("CUSTOMER") }}</b-button
      >
    </div>

    <storeModalVue @updateList="setup(true)"></storeModalVue>
    <div class="content">
      <div class="content-header">
        <searchBar
          :searchFunc="all_clients"
          :showFiltre="true"
          :searchText="searchBarText"
          :filtre="{
            entreprise_id: current_entreprise.id,
            type: this.currenttype,
          }"
          @returnValue="updateSearch($event)"
        ></searchBar>

        <b-modal id="entrepriseModal" ref="entrepriseModal">
          <template #modal-header="{ close }">
            <h5>{{ $t("COMPANY_ID") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>

          <form @submit.prevent="hideModal('entrepriseModal')">
            <div class="center">
              <b-form-group
                :label="$t('COMPANY_ID')"
                v-if="!query && isSuperAdmin"
              >
                <searchInput
                  :list="getAllentreprises"
                  :loader="getentrepriseLoading"
                  label="lib"
                  :searchFunc="all_entreprises"
                  @searchfilter="selectentreprise($event)"
                ></searchInput>
              </b-form-group>
              <b-form-group :label="$t('Catégories')">
                <b-form-select
                  v-model="filtre_type"
                  :options="options"
                ></b-form-select>
              </b-form-group>
            </div>
          </form>
          <!-- hideModal('entrepriseModal') -->
          <template #modal-footer>
            <div class="actionModel">
              <b-button
                variant="success"
                @click="
                  setup(true);
                  hideModal('entrepriseModal');
                "
              >
                <div class="block-spinner">
                  {{ $t("Appliquer") }}
                </div>
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>
      <div class="filtre">
        <p class="table-count">{{ getTotalRowclient }} {{ $t("CLIENTS") }}</p>

        <div class="productTrie" v-if="currenttype != null">
          {{ currenttype }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="deleteCurrentType()"
          />
        </div>

        <div class="productTrie" v-if="current_entreprise.lib != null">
          {{ current_entreprise.lib }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="selectentreprise()"
          />
        </div>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead>
            <tr>
              <th v-if="isSuperAdmin" style="border-radius: 5px 0px 0px 0px">
                {{ $t("SOCIETY") }}
              </th>
              <th>
                {{ $t("TYPE") }}
              </th>
              <th>
                {{ $t("NAME") }}
              </th>
              <th>
                {{ $t("SURNAME") }}
              </th>
              <th>
                {{ $t("POSTAL_CODE") }}
              </th>
              <th>
                {{ $t("CITY") }}
              </th>
              <th>
                {{ $t("PHONE") }}
              </th>
              <th>
                {{ $t("EMAIL") }}
              </th>
              <th>
                {{ $t("CREATED_AT") }}
              </th>
              <th>
                {{ $t("STATUT_DEVIS") }}
              </th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("STATUT_FACTURE") }}
              </th>
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllclients"
              :key="i"
              @click.prevent.stop="handleClient(data)"
            >
              <td v-if="isSuperAdmin" aria-colindex="1" role="cell">
                {{ data.entreprise.lib }}
              </td>
              <td aria-colindex="2" role="cell">
                <span v-if="data.type == 'type.particulier'">Particulier</span>
                <span v-else>Professionnel</span>
              </td>
              <td aria-colindex="3" role="cell">
                {{ data.nom }}
              </td>
              <td aria-colindex="4" role="cell">
                {{ data.prenom }}
              </td>
              <td aria-colindex="5" role="cell">
                {{ data.cp }}
              </td>
              <td aria-colindex="6" role="cell">
                {{ data.ville }}
              </td>
              <td aria-colindex="7" role="cell">
                {{ data.phone ? data.phone.formated_phone : "-" }}
              </td>
              <td aria-colindex="8" role="cell">
                {{ data.email }}
              </td>
              <td aria-colindex="9" role="cell" v-if="data.created_at">
                {{ moment(data.created_at).format("DD-MM-YYYY hh:mm:ss") }}
              </td>
              <td aria-colindex="9" role="cell" v-else>-</td>
              <td aria-colindex="10" role="cell">
                <span v-if="data.devis_signed == 'pending'" class="encours">
                  {{ $t("PENDING") }}
                </span>
                <span v-else-if="data.devis_signed == 'stored'" class="end">
                  {{ $t("VALIDATE") }}
                </span>
                <span v-else class="none">{{ $t("CANCEL") }}</span>
              </td>
              <td aria-colindex="11" role="cell">
                <span v-if="data.facture_signed == 'pending'" class="encours">
                  {{ $t("PENDING") }}
                </span>
                <span v-else-if="data.facture_signed == 'stored'" class="end">
                  {{ $t("VALIDATE") }}
                </span>
                <span v-else class="none">{{ $t("CANCEL") }}</span>
              </td>
              <td aria-colindex="12" role="cell" class="action_col">
                <b-button
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                  v-if="
                    isSuperAdmin ||
                    isentrepriseAdmin ||
                    isAssistant ||
                    isCommercial
                  "
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                  v-if="
                    isSuperAdmin ||
                    isentrepriseAdmin ||
                    isAssistant ||
                    isCommercial
                  "
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-modal
        id="ModelConfigclient"
        ref="ModelConfigclient"
        scrollable
        @hide="
          v$.$reset();
          errorU = null;
        "
      >
        <template #modal-header>
          <h5>{{ $t("EDIT") }} {{ $t("CUSTOMER") }}</h5>
          <b-button size="sm" @click="resetModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <form @submit.prevent="clientModalNew" v-if="clientToConfig">
          <div class="center">
            <b-form-group label="Type">
              <b-form-select
                v-model="v$.clientToConfig.type.$model"
                :options="options"
                :state="validateState('type')"
                aria-describedby="type-feedback"
                @change="v$.$reset()"
              ></b-form-select>
              <error-handle
                :list="v$.clientToConfig.type.$errors"
                id="type-feedback"
              ></error-handle>
              <div v-if="erreurlist.type" class="error-message">
                <ul v-if="Array.isArray(erreurlist.type)">
                  <span v-for="(e, index) in erreurlist.type" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.type }}</span>
              </div>
            </b-form-group>

            <div
              v-if="clientToConfig.type == 'type.professionnel'"
              style="width: 100%"
            >
              <h5 class="titresociete">
                {{ $t("CONTACT_S") }}
              </h5>
              <b-form-group :label="$t('SOCIETY')">
                <b-form-input
                  v-model="v$.clientToConfig.company.$model"
                  oninput="this.value = this.value.toUpperCase()"
                  :state="validateState('company')"
                  aria-describedby="company-feedback"
                  @input="erreurlist.company = null"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.company.$errors"
                  id="company-feedback"
                ></error-handle>
                <div v-if="erreurlist.company" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.company)">
                    <span v-for="(e, index) in erreurlist.company" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.company }}</span>
                </div>
              </b-form-group>
              <b-form-group :label="$t('COMPANY_EMAIL')" label-for="email">
                <b-form-input
                  v-model="v$.clientToConfig.company_email.$model"
                  required
                  type="email"
                  :state="validateState('company_email')"
                  aria-describedby="company_email-feedback"
                  @input="erreurlist.company_email = null"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.company_email.$errors"
                  id="company_email-feedback"
                ></error-handle>
                <div v-if="erreurlist.company_email" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.company_email)">
                    <span
                      v-for="(e, index) in erreurlist.company_email"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.company_email }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('STREET')" label-for="rue">
                <b-form-input
                  v-model="v$.clientToConfig.rue.$model"
                  :state="validateState('rue')"
                  aria-describedby="rue-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.rue.$errors"
                  id="rue-feedback"
                ></error-handle>
                <div v-if="erreurlist.rue" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.rue)">
                    <span v-for="(e, index) in erreurlist.rue" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.rue }}</span>
                </div>
              </b-form-group>
              <b-form-group :label="$t('POSTAL_CODE')" label-for="cp">
                <b-form-input
                  v-model="v$.clientToConfig.cp.$model"
                  :state="validateState('cp')"
                  aria-describedby="cp-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.cp.$errors"
                  id="cp-feedback"
                ></error-handle>
                <div v-if="erreurlist.cp" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.cp)">
                    <span v-for="(e, index) in erreurlist.cp" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.cp }}</span>
                </div>
              </b-form-group>
              <b-form-group :label="$t('CITY')" label-for="ville">
                <b-form-input
                  v-model="v$.clientToConfig.ville.$model"
                  :state="validateState('ville')"
                  aria-describedby="ville-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.ville.$errors"
                  id="ville-feedback"
                ></error-handle>
                <div v-if="erreurlist.ville" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.ville)">
                    <span v-for="(e, index) in erreurlist.ville" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.ville }}</span>
                </div>
              </b-form-group>
              <b-form-group :label="$t('COUNTRY')">
                <multiselect
                  v-model="clientToConfig.pays"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  :options="getAllcountries"
                  label="name"
                  track-by="id"
                >
                  <template slot="noResult">
                    {{ $t("NO_DATA_FOUND") }}
                  </template>
                </multiselect>
                <div v-if="erreurlist.pays" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.pays)">
                    <span v-for="(e, index) in erreurlist.pays" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.pays }}</span>
                </div>
                <div v-if="v$.clientToConfig.pays.$error" class="error-message">
                  {{ $t("REQUIRED_FIELD") }}
                </div>
              </b-form-group>
              <b-form-group :label="$t('COMPANY_PHONE')">
                <template>
                  <phone
                    :tel="clientToConfig.company_phone"
                    @phoneNumber="formatNumberE($event)"
                  ></phone>
                </template>
                <div
                  v-if="v$.clientToConfig.company_phone.$error"
                  class="error-message"
                >
                  {{ $t("REQUIRED_FIELD") }}
                </div>
                <div
                  v-if="clientToConfig.company_phone && phoneValid == false"
                  class="error-message"
                >
                  {{ $t("ERROR_PHONE") }}
                </div>
                <div v-if="erreurlist.company_phone" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.company_phone)">
                    <span
                      v-for="(e, index) in erreurlist.company_phone"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.company_phone }}</span>
                </div>
              </b-form-group>

              <h5 class="titresociete">{{ $t("COMPANY_INTER") }}</h5>

              <b-form-group :label="$t('SURNAME')" label-for="lastname">
                <b-form-input
                  v-model="v$.clientToConfig.prenom.$model"
                  oninput="this.value = this.value.toUpperCase()"
                  :state="validateState('prenom')"
                  aria-describedby="prenom-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.prenom.$errors"
                  id="prenom-feedback"
                ></error-handle>
                <div v-if="erreurlist.prenom" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.prenom)">
                    <span v-for="(e, index) in erreurlist.prenom" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.prenom }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('NAME')">
                <b-form-input
                  v-model="v$.clientToConfig.nom.$model"
                  oninput="this.value = this.value.toUpperCase()"
                  :state="validateState('nom')"
                  aria-describedby="nom-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.nom.$errors"
                  id="nom-feedback"
                ></error-handle>
                <div v-if="erreurlist.nom" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.nom)">
                    <span v-for="(e, index) in erreurlist.nom" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.nom }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('Poste')">
                <b-form-input
                  v-model="v$.clientToConfig.poste.$model"
                  oninput="this.value = this.value.toUpperCase()"
                  :state="validateState('poste')"
                  aria-describedby="poste-feedback"
                  @input="erreurlist.poste = null"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.poste.$errors"
                  id="poste-feedback"
                ></error-handle>
                <div v-if="erreurlist.poste" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.poste)">
                    <span v-for="(e, index) in erreurlist.poste" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.poste }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('PHONE')" label-for="telephone">
                <template>
                  <phone
                    :tel="clientToConfig.phone"
                    @phoneNumber="formatNumber($event)"
                  ></phone>
                </template>
                <!-- <div v-if="erreurlist.tel" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.tel)">
                    <span v-for="(e, index) in erreurlist.tel" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.tel }}</span>
                </div> -->
                <div
                  v-if="v$.clientToConfig.phone.$error"
                  class="error-message"
                >
                  {{ $t("REQUIRED_FIELD") }}
                </div>
                <div
                  v-if="clientToConfig.phone && resultValid == false"
                  class="error-message"
                >
                  {{ $t("ERROR_PHONE") }}
                </div>
              </b-form-group>

              <h5 class="titresociete">{{ $t("COMPANY_BANK") }}</h5>

              <b-form-group :label="$t('ACCOUNT_OWNER')">
                <b-form-input
                  v-model="v$.clientToConfig.titulaire_compte.$model"
                  :state="validateState('titulaire_compte')"
                  aria-describedby="titulaire_compte-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.titulaire_compte.$errors"
                  id="titulaire_compte-feedback"
                ></error-handle>
                <div v-if="erreurlist.titulaire_compte" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.titulaire_compte)">
                    <span
                      v-for="(e, index) in erreurlist.titulaire_compte"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.titulaire_compte }}</span>
                </div>
              </b-form-group>
              <b-form-group label="IBAN" label-for="IBAN">
                <b-form-input
                  v-model="v$.clientToConfig.IBAN.$model"
                  :state="validateState('IBAN')"
                  aria-describedby="IBAN-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.IBAN.$errors"
                  id="IBAN-feedback"
                ></error-handle>
                <div v-if="erreurlist.IBAN" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.IBAN)">
                    <span v-for="(e, index) in erreurlist.IBAN" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.IBAN }}</span>
                </div>
              </b-form-group>

              <b-form-group label="SWIFT_BIC" label-for="SWIFT_BIC">
                <b-form-input
                  v-model="v$.clientToConfig.SWIFT_BIC.$model"
                  :state="validateState('SWIFT_BIC')"
                  aria-describedby="SWIFT_BIC-feedback"
                  oninput="this.value = this.value.toUpperCase()"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.SWIFT_BIC.$errors"
                  id="SWIFT_BIC-feedback"
                ></error-handle>
                <div v-if="erreurlist.SWIFT_BIC" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.SWIFT_BIC)">
                    <span
                      v-for="(e, index) in erreurlist.SWIFT_BIC"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.SWIFT_BIC }}</span>
                </div>
              </b-form-group>

              <hr />

              <b-form-group :label="$t('COMMENTAIRE')" label-for="commentaire">
                <b-form-textarea
                  v-model="clientToConfig.commentaire"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <div v-if="erreurlist.commentaire" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.commentaire)">
                    <span
                      v-for="(e, index) in erreurlist.commentaire"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.commentaire }}</span>
                </div>
              </b-form-group>
            </div>

            <div
              v-if="clientToConfig.type == 'type.particulier'"
              style="width: 100%"
            >
              <b-form-group :label="$t('SURNAME')" label-for="lastname">
                <b-form-input
                  v-model="v$.clientToConfig.prenom.$model"
                  oninput="this.value = this.value.toUpperCase()"
                  :state="validateState('prenom')"
                  aria-describedby="prenom-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.prenom.$errors"
                  id="prenom-feedback"
                ></error-handle>
                <div v-if="erreurlist.prenom" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.prenom)">
                    <span v-for="(e, index) in erreurlist.prenom" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.prenom }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('NAME')">
                <b-form-input
                  v-model="v$.clientToConfig.nom.$model"
                  oninput="this.value = this.value.toUpperCase()"
                  :state="validateState('nom')"
                  aria-describedby="nom-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.nom.$errors"
                  id="nom-feedback"
                ></error-handle>
                <div v-if="erreurlist.nom" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.nom)">
                    <span v-for="(e, index) in erreurlist.nom" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.nom }}</span>
                </div>
              </b-form-group>
              <b-form-group :label="$t('EMAIL')" label-for="email">
                <b-form-input
                  v-model="v$.clientToConfig.email.$model"
                  :state="validateState('email')"
                  aria-describedby="email-feedback"
                  type="email"
                  readonly
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.email.$errors"
                  id="email-feedback"
                ></error-handle>
                <div v-if="erreurlist.email" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.email)">
                    <span v-for="(e, index) in erreurlist.email" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.email }}</span>
                </div>
              </b-form-group>
              <b-form-group :label="$t('STREET')" label-for="rue">
                <b-form-input
                  v-model="v$.clientToConfig.rue.$model"
                  :state="validateState('rue')"
                  aria-describedby="rue-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.rue.$errors"
                  id="rue-feedback"
                ></error-handle>
                <div v-if="erreurlist.rue" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.rue)">
                    <span v-for="(e, index) in erreurlist.rue" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.rue }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('POSTAL_CODE')" label-for="cp">
                <b-form-input
                  v-model="v$.clientToConfig.cp.$model"
                  :state="validateState('cp')"
                  aria-describedby="cp-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.cp.$errors"
                  id="cp-feedback"
                ></error-handle>
                <div v-if="erreurlist.cp" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.cp)">
                    <span v-for="(e, index) in erreurlist.cp" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.cp }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('CITY')" label-for="ville">
                <b-form-input
                  v-model="v$.clientToConfig.ville.$model"
                  :state="validateState('ville')"
                  aria-describedby="ville-feedback"
                ></b-form-input>
                <error-handle
                  :list="v$.clientToConfig.ville.$errors"
                  id="ville-feedback"
                ></error-handle>
                <div v-if="erreurlist.ville" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.ville)">
                    <span v-for="(e, index) in erreurlist.ville" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.ville }}</span>
                </div>
              </b-form-group>

              <b-form-group :label="$t('COUNTRY')">
                <multiselect
                  v-model="clientToConfig.pays"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  :options="getAllcountries"
                  label="name"
                  track-by="id"
                >
                  <template slot="noResult">
                    {{ $t("NO_DATA_FOUND") }}
                  </template>
                </multiselect>
                <div v-if="erreurlist.pays" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.pays)">
                    <span v-for="(e, index) in erreurlist.pays" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.pays }}</span>
                </div>
                <div v-if="v$.clientToConfig.pays.$error" class="error-message">
                  {{ $t("REQUIRED_FIELD") }}
                </div>
              </b-form-group>

              <b-form-group :label="$t('PHONE')" label-for="telephone">
                <template>
                  <phone
                    :tel="clientToConfig.phone"
                    @phoneNumber="formatNumber($event)"
                  ></phone>
                </template>
                <div
                  v-if="v$.clientToConfig.phone.$error"
                  class="error-message"
                >
                  {{ $t("REQUIRED_FIELD") }}
                </div>
                <div
                  v-if="clientToConfig.phone && resultValid == false"
                  class="error-message"
                >
                  {{ $t("ERROR_PHONE") }}
                </div>
              </b-form-group>
              <b-form-group :label="$t('ZONE')">
                <b-form-select
                  v-model="clientToConfig.zone"
                  :options="optionsZone"
                  text-field="text"
                  value-field="value"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- {{ $t("ZONE") }} --</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <div v-if="erreurlist.zone" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.zone)">
                    <span v-for="(e, index) in erreurlist.zone" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.zone }}</span>
                </div>
              </b-form-group>
              <b-form-group :label="$t('PRECARITE')">
                <b-form-select
                  v-model="clientToConfig.precarite"
                  :options="optionsprecarite"
                  text-field="text"
                  value-field="value"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- {{ $t("PRECARITE") }} --</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <div v-if="erreurlist.precarite" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.precarite)">
                    <span
                      v-for="(e, index) in erreurlist.precarite"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.precarite }}</span>
                </div>
              </b-form-group>
              <b-form-group :label="$t('TYPE_CHAUFFAGE')">
                <b-form-select
                  v-model="clientToConfig.type_chauffage"
                  :options="optionstypechauffage"
                  text-field="text"
                  value-field="value"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- {{ $t("TYPE_CHAUFFAGE") }} --</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <div v-if="erreurlist.type_chauffage" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.type_chauffage)">
                    <span
                      v-for="(e, index) in erreurlist.type_chauffage"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.type_chauffage }}</span>
                </div>
              </b-form-group>
              <b-form-group :label="$t('TYPE_LOGEMENT')">
                <b-form-select
                  v-model="clientToConfig.type_logement"
                  :options="optionstypelogement"
                  text-field="text"
                  value-field="value"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- {{ $t("TYPE_LOGEMENT") }} --</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <div v-if="erreurlist.type_logement" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.type_logement)">
                    <span
                      v-for="(e, index) in erreurlist.type_logement"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.type_logement }}</span>
                </div>
              </b-form-group>
            </div>

            <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
              <multiselect
                v-model="clientToConfig.entreprise"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getAllentreprises"
                label="lib"
                track-by="id"
              >
                <template slot="noResult"> {{ $t("NO_DATA_FOUND") }} </template>
              </multiselect>
              <div v-if="erreurlist.entreprise" class="error-message">
                <ul v-if="Array.isArray(erreurlist.entreprise)">
                  <span
                    v-for="(e, index) in erreurlist.entreprise"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.entreprise }}</span>
              </div>
              <div
                v-if="v$.clientToConfig.entreprise.$error"
                class="error-message"
              >
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </b-form-group>
          </div>
        </form>
        <template #modal-footer>
          <b-alert variant="warning" show v-if="errorU">
            {{ errorU }}
          </b-alert>
          <div class="double">
            <b-button variant="danger" @click="resetModal()">
              <div class="block-spinner">
                {{ $t("CANCEL") }}
              </div>
            </b-button>
            <b-button
              variant="success"
              @click="clientModalNew"
              v-if="
                isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial
              "
            >
              <div class="block-spinner">
                {{ $t("CONFIRM") }}
                <div v-if="getclientLoading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <b-modal scrollable id="newStore" ref="newStore" hide-footer>
        <template #modal-header="{ close }">
          <h5>{{ $t("NEW") }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <div class="langues">
          <router-link to="" class="lang" v-b-modal.documentclient>
            {{ $t("DOCUMENT") }}
          </router-link>
          <div @click="goToDevis" class="lang">
            {{ $t("ESTIMATE") }}
          </div>
          <div @click="goToFacture" class="lang">
            {{ $t("INVOICE") }}
          </div>
          <router-link to="/agenda" class="lang">
            {{ $t("Activites") }}
          </router-link>
        </div>
      </b-modal>

      <b-modal
        scrollable
        id="validate_doc"
        ref="Modelclient"
        size="lg"
        centered
        :hide-footer="
          !isSuperAdmin && !isentrepriseAdmin && !isAssistant && !isCommercial
        "
      >
        <template #modal-header="{ close }">
          <h5>{{ $t("CLIENT_DETAILS") }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <div class="corps">
          <header>
            <div class="left">
              <div
                class="circle"
                :class="{ pro: clientDetail.type == 'type.professionnel' }"
                v-if="clientDetail && clientDetail.nom && clientDetail.prenom"
              >
                {{ clientDetail.prenom.slice(0, 1)
                }}{{ clientDetail.nom.slice(0, 1) }}
              </div>
              <div class="info">
                <h2>{{ clientDetail.prenom }} {{ clientDetail.nom }}</h2>
                <p>
                  <span v-if="clientDetail.type == 'type.particulier'">{{
                    $t("PARTICULAR")
                  }}</span>
                  <span v-else>{{ $t("PROFESSIONAL") }}</span>
                </p>
              </div>
            </div>
            <div
              class="right"
              v-if="
                isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial
              "
            >
              <div class="action" v-b-modal.newStore>
                <font-awesome-icon icon="plus-circle" class="mr-2" />
                <span>{{ $t("ADD") }}</span>
              </div>
            </div>
          </header>
          <div class="information">
            <div class="info">
              <div class="icone">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.694"
                  height="23.092"
                  viewBox="0 0 17.694 23.092"
                >
                  <path
                    id="Icon_awesome-map-marker-alt"
                    data-name="Icon awesome-map-marker-alt"
                    d="M7.265,21.156C1.137,12.273,0,11.362,0,8.1a8.1,8.1,0,0,1,16.194,0c0,3.265-1.137,4.176-7.265,13.059a1.013,1.013,0,0,1-1.664,0ZM8.1,11.471A3.374,3.374,0,1,0,4.723,8.1,3.374,3.374,0,0,0,8.1,11.471Z"
                    transform="translate(0.75 0.75)"
                    fill="none"
                    stroke="#28367a"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
              <p>{{ clientDetail.rue }}, {{ clientDetail.ville }}</p>
            </div>
            <div class="hr"></div>
            <div class="info">
              <div class="icone">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="svg"
                  style="margin: 8px 0 0"
                >
                  <path
                    id="ios-email-outline"
                    d="M4.5,9V22.981H25.283V9Zm10.391,7.641L6.08,9.874H23.7ZM5.366,22.107V10.425L11.6,15.209,7.91,19.431l.109.11,4.27-3.8,2.6,2,2.6-2,4.27,3.8.109-.11L18.188,15.2l6.229-4.778V22.107Z"
                    transform="translate(-4.5 -9)"
                    fill="#28367a"
                  />
                </svg>
              </div>
              <p>{{ clientDetail.email }}</p>
            </div>
            <div class="hr"></div>
            <div class="info">
              <div class="icone">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.229"
                  height="21.411"
                  viewBox="0 0 21.229 21.411"
                >
                  <path
                    id="ios-call"
                    d="M24.2,20.536a17.051,17.051,0,0,0-3.561-2.4c-1.067-.517-1.458-.506-2.213.042-.629.459-1.035.885-1.759.725a10.5,10.5,0,0,1-3.534-2.638,10.5,10.5,0,0,1-2.615-3.567c-.153-.735.27-1.141.718-1.775.544-.762.56-1.157.042-2.234A16.878,16.878,0,0,0,8.893,5.092c-.777-.784-.951-.613-1.379-.459a7.764,7.764,0,0,0-1.262.677A3.832,3.832,0,0,0,4.736,6.925c-.3.656-.65,1.877,1.126,5.065a28.234,28.234,0,0,0,4.924,6.627l.005.005.005.005A28.13,28.13,0,0,0,17.362,23.6c3.159,1.792,4.369,1.439,5.019,1.136a3.756,3.756,0,0,0,1.6-1.53,8,8,0,0,0,.671-1.274c.153-.432.328-.608-.455-1.391Z"
                    transform="translate(-4.019 -4.039)"
                    fill="none"
                    stroke="#28367a"
                    stroke-width="1"
                  />
                </svg>
              </div>
              <p>
                {{
                  clientDetail.phone ? clientDetail.phone.formated_phone : "-"
                }}
              </p>
            </div>
          </div>
          <b-modal scrollable id="documentclient" ref="documentclient">
            <template #modal-header="{ close }">
              <h5>{{ $t("NEW") }} {{ $t("document") }}</h5>
              <b-button size="sm" @click="close()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.028"
                  height="17.028"
                  viewBox="0 0 17.028 17.028"
                >
                  <path
                    id="Icon_material-close"
                    data-name="Icon material-close"
                    d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                    transform="translate(-7.5 -7.5)"
                    fill="#393939"
                  />
                </svg>
              </b-button>
            </template>
            <form>
              <div class="center">
                <b-form-group label="Nom ficher" label-for="lib">
                  <b-form-input
                    id="lib"
                    v-model="document.lib"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="document" label-for="documentfile">
                  <b-form-file
                    v-model="document.file"
                    id="documentfile"
                    placeholder=""
                    drop-placeholder=""
                    class="file"
                  ></b-form-file>
                </b-form-group>
                <b-form-group label="Categorie" label-for="Categorie">
                  <multiselect
                    v-model="document.categorie"
                    :searchable="true"
                    :close-on-select="true"
                    :multiple="false"
                    :options="getAllCategorie"
                    label="name"
                    track-by="id"
                  >
                    <template slot="noResult">
                      {{ $t("NO_DATA_FOUND") }}
                    </template>
                  </multiselect>
                </b-form-group>
                <b-form-group label-for="Description" label="Description">
                  <b-form-textarea
                    id="desc"
                    v-model="document.description"
                    placeholder="Description..."
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </form>
            <template #modal-footer>
              <div class="actionModel">
                <b-button
                  v-if="filetoupdate.lib == null"
                  variant="success"
                  @click="validate_doc"
                >
                  <div class="block-spinner">
                    {{ $t("CONFIRM") }}
                    <div v-if="getclientLoading" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </div>
                </b-button>
                <b-button v-else variant="success" @click="confirmUPdate">
                  <div class="block-spinner">
                    {{ $t("CONFIRM") }}
                    <div v-if="getclientLoading" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </div>
                </b-button>
              </div>
            </template>
          </b-modal>

          <footer>
            <div class="type">
              <div
                class="type__doc"
                :class="{ active: step == 1 }"
                @click="goDoc"
              >
                {{ $t("DOCUMENT") }}
              </div>
              <div
                v-if="
                  isSuperAdmin ||
                  isentrepriseAdmin ||
                  isAssistant ||
                  isTechnicien ||
                  isCommercial
                "
                class="type__doc"
                :class="{ active: step == 2 }"
                @click="getDevis"
              >
                {{ $t("ESTIMATE") }}
              </div>
              <div
                v-if="
                  isSuperAdmin ||
                  isentrepriseAdmin ||
                  isAssistant ||
                  isCommercial
                "
                class="type__doc"
                :class="{ active: step == 3 }"
                @click="getFacture"
              >
                {{ $t("INVOICE") }}
              </div>
              <div
                class="type__doc"
                :class="{ active: step == 4 }"
                @click="getAct"
              >
                {{ $t("Activites") }}
              </div>
            </div>

            <!-- Document -->
            <div v-if="step == 1">
              <div class="liste" v-if="!loadingInfo && getdatafile.length">
                <div
                  class="client_file"
                  v-for="(file, i) in getdatafile"
                  :key="i"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.813"
                    height="33.75"
                    viewBox="0 0 29.813 33.75"
                  >
                    <path
                      id="file"
                      d="M23.528,1.125H3.375v33.75H33.188V10.784Zm7.41,10.591v.1H22.5V3.375h.1ZM5.625,32.625V3.375H20.25V14.063H30.938V32.626Z"
                      transform="translate(-3.375 -1.125)"
                      fill="#393939"
                    />
                  </svg>

                  <div class="hr mx-0"></div>
                  <h2>{{ file.lib }}</h2>
                  <div class="hr mx-0"></div>
                  <p class="description">{{ file.description }}</p>
                  <div class="hr mx-0"></div>

                  <div
                    class="action"
                    v-if="
                      isSuperAdmin ||
                      isentrepriseAdmin ||
                      isAssistant ||
                      isCommercial
                    "
                  >
                    <div @click="updateFicher(file)">
                      <font-awesome-icon icon="pencil-alt" />
                    </div>
                    <div @click="download(file)">
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="svgtele">
                          <g
                            id="Icon_feather-download"
                            data-name="Icon feather-download"
                            transform="translate(0.75 0.75)"
                          >
                            <path
                              id="Tracé_78100"
                              data-name="Tracé 78100"
                              d="M16.739,22.5v2.72a1.36,1.36,0,0,1-1.36,1.36H5.86A1.36,1.36,0,0,1,4.5,25.22V22.5"
                              transform="translate(-4.5 -14.34)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5"
                            />
                            <path
                              id="Tracé_78101"
                              data-name="Tracé 78101"
                              d="M10.5,15l3.4,3.4L17.3,15"
                              transform="translate(-7.78 -10.24)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5"
                            />
                            <path
                              id="Tracé_78102"
                              data-name="Tracé 78102"
                              d="M18,12.66V4.5"
                              transform="translate(-11.88 -4.5)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                    <div @click="deleteFiche(file)">
                      <font-awesome-icon icon="trash" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="liste" v-if="!loadingInfo && !getdatafile.length">
                <p>{{ $t("NO_DATA_FOUND") }}</p>
              </div>
              <div v-if="loadingInfo" class="load_doc">
                <b-spinner label="Spinning"></b-spinner>
              </div>
            </div>

            <!-- Devis -->
            <div v-if="step == 2">
              <div class="liste" v-if="!getdevisLoading && getTotalRowdevis">
                <div
                  class="devis_facture"
                  v-for="(devis, i) in getAlldevis"
                  :key="i"
                  @click="chooseDevis(devis)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.512"
                    height="33.95"
                    viewBox="0 0 25.512 33.95"
                    style="margin-right: 8px"
                  >
                    <g id="edition" transform="translate(0.1 0.1)">
                      <path
                        id="Path_2209"
                        data-name="Path 2209"
                        d="M89.312,0H67.428V3.428H64V33.75H85.885V30.322h3.428ZM64.791,32.959V4.219H79.82V9.492h5.273V32.959ZM80.611,4.778,84.534,8.7H80.611Zm7.91,24.753H85.885V8.933L80.38,3.428H68.219V.791h20.3Z"
                        transform="translate(-64)"
                        fill="#393939"
                        stroke="#393939"
                        stroke-width="0.2"
                      />
                      <path
                        id="Path_2210"
                        data-name="Path 2210"
                        d="M118,116h10.547v.791H118Z"
                        transform="translate(-114.44 -108.354)"
                        fill="#393939"
                        stroke="#393939"
                        stroke-width="0.2"
                      />
                      <path
                        id="Path_2211"
                        data-name="Path 2211"
                        d="M118,148h8.437v.791H118Z"
                        transform="translate(-114.44 -138.244)"
                        fill="#393939"
                        stroke="#393939"
                        stroke-width="0.2"
                      />
                      <path
                        id="Path_2212"
                        data-name="Path 2212"
                        d="M206,196h3.428v.791H206Z"
                        transform="translate(-196.639 -183.08)"
                        fill="#393939"
                        stroke="#393939"
                        stroke-width="0.2"
                      />
                      <path
                        id="Path_2213"
                        data-name="Path 2213"
                        d="M206,228h3.428v.791H206Z"
                        transform="translate(-196.639 -212.971)"
                        fill="#393939"
                        stroke="#393939"
                        stroke-width="0.2"
                      />
                      <path
                        id="Path_2214"
                        data-name="Path 2214"
                        d="M118,292h9.228v.791H118Z"
                        transform="translate(-114.44 -272.752)"
                        fill="#393939"
                        stroke="#393939"
                        stroke-width="0.2"
                      />
                      <path
                        id="Path_2215"
                        data-name="Path 2215"
                        d="M118,356h9.228v.791H118Z"
                        transform="translate(-114.44 -332.533)"
                        fill="#393939"
                        stroke="#393939"
                        stroke-width="0.2"
                      />
                      <path
                        id="Path_2216"
                        data-name="Path 2216"
                        d="M206,260h3.428v.791H206Z"
                        transform="translate(-196.639 -242.861)"
                        fill="#393939"
                        stroke="#393939"
                        stroke-width="0.2"
                      />
                      <path
                        id="Path_2217"
                        data-name="Path 2217"
                        d="M118,388h9.228v.791H118Z"
                        transform="translate(-114.44 -362.424)"
                        fill="#393939"
                        stroke="#393939"
                        stroke-width="0.2"
                      />
                      <path
                        id="Path_2218"
                        data-name="Path 2218"
                        d="M118,420h7.383v.791H118Z"
                        transform="translate(-114.44 -392.314)"
                        fill="#393939"
                        stroke="#393939"
                        stroke-width="0.2"
                      />
                      <path
                        id="Path_2219"
                        data-name="Path 2219"
                        d="M118,324h9.228v.791H118Z"
                        transform="translate(-114.44 -302.643)"
                        fill="#393939"
                        stroke="#393939"
                        stroke-width="0.2"
                      />
                      <path
                        id="Path_2220"
                        data-name="Path 2220"
                        d="M275.955,189.582h-.791V188h-2.373v1.582H272v13.689l1.978,3.3,1.978-3.3V191.428h.791v4.878h.791v-5.669h-1.582Zm-2.373-.791h.791v.791h-.791Zm1.582,1.582v1.318h-2.373v-1.318Zm-2.373,12.393V192.482h2.373v10.283Zm.3.791h1.767l-.883,1.472Z"
                        transform="translate(-258.289 -175.607)"
                        fill="#393939"
                        stroke="#393939"
                        stroke-width="0.2"
                      />
                      <path
                        id="Path_2221"
                        data-name="Path 2221"
                        d="M117.01,196H112v5.01h5.01Zm-.791,4.219h-3.428v-3.428h3.428Z"
                        transform="translate(-108.836 -183.08)"
                        fill="#393939"
                        stroke="#393939"
                        stroke-width="0.2"
                      />
                    </g>
                  </svg>
                  <div class="hr mx-0"></div>
                  <h2>{{ devis.client.prenom }} {{ devis.client.nom }}</h2>
                  <div class="hr mx-0"></div>
                  <p>{{ devis.created_at }}</p>
                  <div class="hr mx-0"></div>
                  <p v-if="devis.is_signed == 'pending'" class="status">
                    {{ $t("PENDING") }}
                  </p>
                  <p v-else-if="devis.is_signed == 'stored'" class="stat">
                    {{ $t("VALIDATE") }}
                  </p>
                  <p v-else class="none">{{ $t("CANCEL") }}</p>
                  <font-awesome-icon icon="chevron-right" />
                </div>
              </div>
              <div class="liste" v-if="!getdevisLoading && !getTotalRowdevis">
                <p>{{ $t("NO_DATA_FOUND") }}</p>
              </div>
              <div v-if="getdevisLoading" class="load_doc">
                <b-spinner label="Spinning"></b-spinner>
              </div>
            </div>

            <!-- Facture -->
            <div v-if="step == 3">
              <div class="liste" v-if="!getFactureLoading && getfactureRows">
                <div
                  class="devis_facture"
                  v-for="(facture, i) in getAllFacture"
                  :key="i"
                  @click="chooseFacture(facture)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.813"
                    height="33.75"
                    viewBox="0 0 29.813 33.75"
                  >
                    <path
                      id="file"
                      d="M23.528,1.125H3.375v33.75H33.188V10.784Zm7.41,10.591v.1H22.5V3.375h.1ZM5.625,32.625V3.375H20.25V14.063H30.938V32.626Z"
                      transform="translate(-3.375 -1.125)"
                      fill="#393939"
                    />
                  </svg>

                  <div class="hr mx-0"></div>
                  <h2>{{ facture.client.prenom }} {{ facture.client.nom }}</h2>
                  <div class="hr mx-0"></div>
                  <p>{{ facture.created_at }}</p>
                  <div class="hr mx-0"></div>
                  <p v-if="facture.is_signed == 'pending'" class="status">
                    {{ $t("PENDING") }}
                  </p>
                  <p v-else-if="facture.is_signed == 'stored'" class="stat">
                    {{ $t("VALIDATE") }}
                  </p>
                  <p v-else class="none">{{ $t("CANCEL") }}</p>
                  <font-awesome-icon icon="chevron-right" />
                </div>
              </div>
              <div class="liste" v-if="!getFactureLoading && !getfactureRows">
                <p>{{ $t("NO_DATA_FOUND") }}</p>
              </div>
              <div v-if="getFactureLoading" class="load_doc">
                <b-spinner label="Spinning"></b-spinner>
              </div>
            </div>

            <!-- Activités -->
            <div v-if="step == 4">
              <div class="agenda" v-if="!getagendaLoading && getAgenda.length">
                <h1>{{ $t("PROGRAM") }}</h1>
                <div v-for="(agenda, i) in getAgenda" :key="i">
                  <div v-if="!agenda.done" class="event">
                    <h2>{{ agenda.name }}</h2>
                    <p>
                      {{ moment(agenda.start).format("DD-MM-YYYY hh:mm:ss") }}
                    </p>
                  </div>
                </div>
                <h1>{{ $t("DONE") }}</h1>
                <div v-for="(agenda, i) in getAgenda" :key="i">
                  <div v-if="agenda.done" class="event">
                    <h2>{{ agenda.name }}</h2>
                    <p>
                      {{ moment(agenda.start).format("DD-MM-YYYY hh:mm:ss") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="liste" v-if="!getagendaLoading && !getAgenda.length">
                <p>{{ $t("NO_DATA_FOUND") }}</p>
              </div>
              <div v-if="getagendaLoading" class="load_doc">
                <b-spinner label="Spinning"></b-spinner>
              </div>
            </div>
          </footer>
        </div>
        <template #modal-footer>
          <div
            class="double"
            v-if="
              isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial
            "
          >
            <b-button
              variant="danger"
              @click.prevent.stop="handleDelete(clientDetail)"
            >
              <div class="block-spinner">
                {{ $t("DELETE") }}
              </div>
            </b-button>

            <b-button
              variant="success"
              @click.prevent.stop="handleUpdate(clientDetail, clientDetailRef)"
            >
              <div class="block-spinner">
                {{ $t("EDIT") }}
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <deleteModalVue
        :loader="getclientLoading"
        :elemToDelete="clientToDelete"
        :elemDelete="delete_client"
        @updateList="setup(false)"
      ></deleteModalVue>

      <b-pagination
        v-model="page"
        :total-rows="getTotalRowclient"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getclientLoading"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche to="/how-it-work/client"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import moment from "moment";
import searchInput from "../components/ui/searchInput.vue";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  minLength,
  requiredIf,
  alphaNum,
  numeric,
  email,
} from "@vuelidate/validators";
import deleteModalVue from "../components/ui/deleteModal.vue";
import storeModalVue from "../components/Ajoutmodel/clientmodale.vue";
import searchBar from "../components/ui/searchBar.vue";
import errorHandle from "../components/ui/errorHandle.vue";
import phone from "../components/ui/phone.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchBar,
    searchInput,
    deleteModalVue,
    storeModalVue,
    errorHandle,
    phone,
    CommentMarche,
  },
  data() {
    return {
      statuscheckbox: "Autre adresse",
      downloadUrl: domains.download,
      filetoupdate: {
        lib: null,
        description: null,
        categorie: null,
        client_id: null,
        file: null,
      },
      erreurlist: {
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: null,
        type: null,
        entreprise: null,
        pays: null,
        SWIFT_BIC: null,
        IBAN: null,
        titulaire_compte: null,
        commentaire: null,
        company: null,
        company_email: null,
        company_phone: null,
        zone: null,
        precarite: null,
        type_chauffage: null,
        type_logement: null,
      },
      vueTel: {
        preferredCountries: ["FR", "TN"],
        placeholder: "Enter your phone",
        mode: "auto",
        inputOptions: {
          showDialCode: true,
        },
        disabledFormatting: false,
        validCharactersOnly: true,
        wrapperClasses: "country-phone-input",
      },
      document: {
        lib: null,
        description: null,
        categorie: null,
        client_id: null,
        file: null,
      },
      search: "",
      clientToConfig: {
        type: null,
        pays: {
          iso_code: null,
        },
        tel: {
          phone_number: null,
          phone_iso_code: null,
        },
        company_phone: {
          phone_number: null,
          iso_code: null,
        },
      },
      clientToConfigRef: null,
      clientToDelete: null,
      clientDetail: {
        type: null,
        email: null,
        tel: null,
        rue: null,
        ville: null,
      },
      clientDetailRef: null,
      page: 1,
      perPage: 10,
      optionsprecarite: [
        { value: "modeste", text: this.$t("Modeste") },
        { value: "tres_modeste", text: this.$t("Tmodeste") },
        { value: "classique", text: this.$t("Classique") },
      ],
      optionstypelogement: [
        { value: "maison_individuelle", text: this.$t("Maison individuelle") },
        { value: "appartement", text: this.$t("Appartement") },
      ],
      optionstypechauffage: [
        { value: "combustible_bois", text: this.$t("Combustible/Bois") },
        { value: "electrique", text: this.$t("Electrique") },
        { value: "gaz", text: this.$t("Gaz") },
        { value: "fuel", text: this.$t("Fuel") },
        { value: "bois", text: this.$t("Bois") },
        { value: "pac", text: "PAC" },
      ],
      optionsZone: [
        { value: "h1", text: "H1" },
        { value: "h2", text: "H2" },
        { value: "h3", text: "H3" },
      ],
      options: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
      current_entreprise: {
        id: null,
      },
      filtre_type: null,
      currenttype: null,
      errorU: null,
      documents: null,
      infoClient: 0,
      loadingInfo: false,
      step: 1,
      resultValid: null,
      phoneValid: null,
    };
  },
  validations() {
    return {
      clientToConfig: {
        type: { required },
        nom: { required, maxLength: maxLength(100) },
        prenom: { required, maxLength: maxLength(100) },
        rue: { required, maxLength: maxLength(100) },
        cp: {
          required,
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        ville: { required, maxLength: maxLength(100) },
        phone: {
          phone_number: { required },
        },
        pays: { required },
        entreprise: { requiredIf: requiredIf(this.isSuperAdmin) },
        company: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(100),
        },
        company_phone: {
          phone_number: { requiredIf: requiredIf(this.isProfessionel) },
        },
        poste: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(40),
        },
        SWIFT_BIC: {
          minLength: minLength(8),
          maxLength: maxLength(11),
          alphaNum,
        },
        company_email: {
          email,
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(50),
        },
        email: { required, email, maxLength: maxLength(50) },
        IBAN: { maxLength: maxLength(34), alphaNum },
        titulaire_compte: { maxLength: maxLength(40) },
      },
    };
  },
  methods: {
    ...mapActions([
      "all_countries",
      "all_users",
      "get_data_file",
      "allCategorie",
      "edit_client",
      "all_clients",
      "delete_client",
      "all_entreprises",
      "client",
      "add_to_datafile",
      "delete_from_datafile",
      "update_datafile",
      "devis",
      "factures",
      "all_rendezvous",
      "pays",
      "entreprise",
    ]),

    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },
    resetModal() {
      this.documents = null;
      this.step = 1;
      this.document = {
        lib: null,
        description: null,
        categorie: null,
        file: null,
      };
      this.erreurlist = {
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: null,
        type: null,
        entreprise: null,
        pays: null,
        SWIFT_BIC: null,
        IBAN: null,
        titulaire_compte: null,
        commentaire: null,
        company: null,
        company_email: null,
        company_phone: null,
        zone: null,
        precarite: null,
        type_chauffage: null,
        type_logement: null,
      };
      this.errorS = null;
      this.errorD = null;
      this.errorU = null;
      this.$refs["ModelConfigclient"].hide();
    },

    validateState(name) {
      const { $dirty, $error } = this.v$.clientToConfig[name];
      return $dirty ? !$error : null;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleUpdate(CAT, ref) {
      this.clientToConfig = { ...CAT };
      this.clientToConfigRef = ref;
      this.$refs["ModelConfigclient"].show();
    },
    handleClient(CAT) {
      this.infoClient = 0;
      this.clientDetailRef = CAT;
      this.clientDetail = { ...CAT };
      this.goDoc();
      this.$refs["Modelclient"].show();
    },
    handleStore(modal) {
      this.$root.$emit("bv::show::modal", modal);
    },
    handleDelete(CAT) {
      this.clientToDelete = { ...CAT };
      this.$refs["Modelclient"].hide();
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    async clientModalNew() {
      const isFormCorrect = await this.v$.clientToConfig.$error;
      if (isFormCorrect) return;
      if (this.resultValid == false) {
        return;
      }

      if (this.clientToConfig.company_phone && this.phoneValid == false) {
        return;
      }
      this.erreurlist = {
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: null,
        type: null,
        entreprise: null,
        pays: null,
        SWIFT_BIC: null,
        IBAN: null,
        titulaire_compte: null,
        commentaire: null,
        company: null,
        company_email: null,
        company_phone: null,
        zone: null,
        precarite: null,
        type_chauffage: null,
        type_logement: null,
      };

      this.errorU = null;
      const data = {
        id: this.clientToConfig.id,
      };

      // Format numero tel
      var num = this.clientToConfig.phone.phone_number.indexOf(" ");

      data.tel = this.clientToConfig.phone.phone_number
        .slice(num + 1)
        .split(" ")
        .join("");

      data.phone_number = data.tel;
      data.phone_iso_code = this.clientToConfig.phone.phone_iso_code;

      // Format numero tel company
      if (this.clientToConfig.type == "type.professionnel") {
        console.log(this.clientToConfig.company_phone);
        var numE = this.clientToConfig.company_phone.phone_number.indexOf(" ");
        data.company_tel = this.clientToConfig.company_phone.phone_number
          .slice(numE + 1)
          .split(" ")
          .join("");

        data.company_phone_number = data.company_tel;
        data.company_phone_iso_code =
          this.clientToConfig.company_phone.iso_code;
      }

      if (this.clientToConfig.type) {
        data.type = this.clientToConfig.type;
      }
      if (this.clientToConfig.company) {
        data.company = this.clientToConfig.company;
      }
      if (this.clientToConfig.company_email) {
        data.company_email = this.clientToConfig.company_email;
      }
      if (this.clientToConfig.prenom) {
        data.prenom = this.clientToConfig.prenom;
      }
      if (this.clientToConfig.nom) {
        data.nom = this.clientToConfig.nom;
      }
      if (this.clientToConfig.rue) {
        data.rue = this.clientToConfig.rue;
      }
      if (this.clientToConfig.cp) {
        data.cp = this.clientToConfig.cp;
      }
      if (this.clientToConfig.ville) {
        data.ville = this.clientToConfig.ville;
      }
      if (this.clientToConfig.pays) {
        data.pays = this.clientToConfig.pays.id;
      }
      if (this.clientToConfig.zone) {
        data.zone = this.clientToConfig.zone;
      }
      if (this.clientToConfig.precarite) {
        data.precarite = this.clientToConfig.precarite;
      }
      if (this.clientToConfig.type_chauffage) {
        data.type_chauffage = this.clientToConfig.type_chauffage;
      }
      if (this.clientToConfig.type_logement) {
        data.type_logement = this.clientToConfig.type_logement;
      }
      if (this.clientToConfig.entreprise) {
        data.entreprise_id = this.clientToConfig.entreprise.id;
      }
      if (this.clientToConfig.SWIFT_BIC) {
        data.SWIFT_BIC = this.clientToConfig.SWIFT_BIC;
      }
      if (this.clientToConfig.IBAN) {
        data.IBAN = this.clientToConfig.IBAN;
      }
      if (this.clientToConfig.titulaire_compte) {
        data.titulaire_compte = this.clientToConfig.titulaire_compte;
      }
      if (this.clientToConfig.poste) {
        data.poste = this.clientToConfig.poste;
      }
      if (this.clientToConfig.commentaire) {
        data.commentaire = this.clientToConfig.commentaire;
      }
      if (this.clientToConfig.poste) {
        data.poste = this.clientToConfig.poste;
      }

      await this.edit_client({
        refObject: this.clientToConfigRef,
        data: data,
      })
        .then(() => {
          this.setup();
          this.errorU = null;
          this.hideModal("ModelConfigclient");
          this.hideModal("Modelclient");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },

    formatNumber(event) {
      this.clientToConfig.phone = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.resultValid = event.isValid;
    },

    formatNumberE(event) {
      this.clientToConfig.company_phone = {
        phone_number: event.number,
        iso_code: event.country.iso2,
      };
      this.phoneValid = event.isValid;
      this.erreurlist.company_phone = null;
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    pagination(paginate) {
      this.page = paginate;
      this.all_clients({
        search: this.search,
        page: this.page,
        per_page: this.perPage,
        entreprise_id: this.current_entreprise.id,
      });
    },

    deleteCurrentType() {
      this.filtre_type = null;
      this.setup();
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    selectentreprise(query) {
      this.search = "";
      if (query) {
        this.all_clients({
          page: this.page,
          per_page: this.perPage,
          entreprise_id: query.id,
        });

        this.current_entreprise = query;
      } else {
        this.current_entreprise = {
          lib: null,
        };
        this.all_clients({
          page: this.page,
          per_page: this.perPage,
        });
      }

      this.hideModal("entrepriseModal");
    },

    validate_doc() {
      var bodyFormData = new FormData();
      bodyFormData.append("lib", this.document.lib);
      bodyFormData.append("category_file_id", this.document.categorie.id);
      bodyFormData.append("description", this.document.description);
      bodyFormData.append("client_id", this.clientDetail.id);
      bodyFormData.append("file", this.document.file);
      this.add_to_datafile(bodyFormData).then(() => {
        this.get_data_file(this.clientDetail);

        this.hideModal("documentclient");
        this.hideModal("newStore");

        this.resetModal();
      });
    },
    deleteFiche(item) {
      this.loadingInfo = true;
      this.delete_from_datafile(item).then(() => {
        this.loadingInfo = false;
      });
    },
    updateFicher(item) {
      this.document = { ...item };
      this.filetoupdate = this.document;
      this.$refs["documentclient"].show(item);
    },
    confirmUPdate() {
      this.filetoupdate.category_file_id = this.filetoupdate.categorie.id;
      this.update_datafile(this.filetoupdate).then(() => {
        this.resetModal();
        this.hideModal("documentclient");
      });
    },
    download(item) {
      if (item.file) window.open(this.downloadUrl + item.file, "_blank");
    },
    goDoc() {
      this.step = 1;
      this.loadingInfo = true;
      this.get_data_file(this.clientDetail).then(() => {
        this.infoClient = 1;
        this.loadingInfo = false;
      });
    },

    getDevis() {
      this.step = 2;
      this.devis({
        client: this.clientDetail.id,
      });
    },

    getFacture() {
      this.step = 3;
      this.factures({
        client: this.clientDetail.id,
      });
    },

    getAct() {
      this.step = 4;
      this.all_rendezvous({
        client_id: this.clientDetail.id,
        entreprise_id: this.clientDetail.entreprise.id,
      });
    },

    chooseDevis(devis) {
      this.$router.push("/devis/list?id=" + devis.devis.id);
    },

    chooseFacture(facture) {
      this.$router.push(
        "/facture/list?id=" + facture.facture[facture.facture.length - 1].id
      );
    },

    goToDevis() {
      this.$router.push(`/devis/manuel?client=${this.clientDetail.id}`);
    },

    goToFacture() {
      this.$router.push(`/facture/manuelle?client=${this.clientDetail.id}`);
    },

    setup(refresh = false) {
      if (refresh) {
        this.page = 1;
        this.search = null;
      }
      this.currenttype = this.filtre_type;
      if (this.$route.query.entreprise) {
        this.current_entreprise.id = this.$route.query.entreprise;
        this.entreprise({ id: this.$route.query.entreprise });
        this.all_clients({
          page: this.page,
          per_page: this.perPage,
          entreprise_id: this.$route.query.entreprise,
          type: this.currenttype,
        });
      } else {
        if (this.isSuperAdmin) {
          this.all_clients({
            page: this.page,
            per_page: this.perPage,
            entreprise_id: this.current_entreprise.id,
            type: this.currenttype,
          });
        } else {
          this.all_clients({
            page: this.page,
            per_page: this.perPage,
            entreprise_id: this.getOnlineUser.entreprise.id,
            type: this.currenttype,
          });
        }
      }
    },
  },

  mounted() {
    this.setup(true);

    if (this.isSuperAdmin) this.all_entreprises({ page: 1, per_page: 1000 });
    if (this.$route.query.id) {
      this.client(this.$route.query.id).then(() => {
        this.handleClient(this.getclient);
      });
    }
    if (this.isSuperAdmin || this.isentrepriseAdmin)
      this.all_users({ page: 1, per_page: 100 });
    this.allCategorie({ page: 1, per_page: 1000 });
    this.all_countries({ page: 1, perPage: 1000 });
  },

  computed: {
    ...mapGetters([
      "getAllcountries",
      "getdatafile",
      "getAllCategorie",
      "getAllclients",
      "getclientLoading",
      "getTotalRowclient",
      "getAllentreprises",
      "getentrepriseLoading",
      "getAllUsers",
      "getTabs",
      "getclient",
      "getOnlineUser",
      "getdevisLoading",
      "getTotalRowdevis",
      "getAlldevis",
      "getFactureLoading",
      "getfactureRows",
      "getAllFacture",
      "getagendaLoading",
      "getAgenda",
      "getdictionnaire",
      "getSelectedLang",
      "getPays",
      "getentreprise",
      "getErreur",
    ]),

    translations() {
      var trans = {
        countrySelectorLabel: "Choisir un pays",
        phoneNumberLabel: "Numéro de téléphone",
      };
      this.getdictionnaire.forEach((element) => {
        if (element.key == "countrySelectorLabel")
          trans.countrySelectorLabel = element.lib;
        if (element.key == "phoneNumberLabel")
          trans.phoneNumberLabel = element.lib;
      });

      return trans;
    },

    query() {
      return this.$route.query.entreprise;
    },

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
    isTechnicien() {
      return this.getOnlineUser.role == "user.technicien";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isProfessionel() {
      var bool = false;
      if (this.clientToConfig.type == "type.professionnel") bool = true;
      return bool;
    },
    searchBarText() {
      return `${this.$t("CLIENT_SEARCH")} ( ${this.$t("NAME")}, ${this.$t(
        "SURNAME"
      )}, ${this.$t("EMAIL")} )`;
    },
  },
};
</script>

<style lang="scss" scoped>
.country-phone-input .dropdown:focus {
  outline: none;
  color: grey;
}
.titresociete {
  color: #334081;
  width: 100%;
}

.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}
body {
  background: #fafdff;
}

.body {
  padding: 4px 0;
  box-shadow: 1px 1px 8px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}

.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin-right: 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

.corps {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 2px solid #e9ecef;

    .left {
      display: flex;
      align-items: center;

      .circle {
        min-width: 52px;
        min-height: 52px;
        border-radius: 50%;
        background-color: #28367a;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 800;
        margin-right: 8px;
        text-transform: uppercase;
      }

      .pro {
        background-color: #287a6c;
      }

      .info {
        h2,
        p {
          margin: 0;
        }
        h2 {
          font-size: 16px;
          font-weight: 800;
        }
        p {
          font-size: 14px;
          color: #515151;
        }
      }
    }

    .right {
      .action {
        background-color: #28367a;
        border-radius: 4px;
        color: #fff;
        padding: 10px 0;
        font-size: 13px;
        display: flex;
        width: 130px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        span {
          line-height: 14px;
        }
      }
    }
  }

  hr {
    @media only screen and (max-width: 900px) {
      display: none;
    }
    height: 32px;
    border-left: 2px solid #d8d8d8;
    margin: 12px;
  }

  .hr {
    @media only screen and (max-width: 900px) {
      display: none;
    }
    height: 42px;
    margin-right: 12px;
    border-left: 2px solid #d8d8d8;
  }
  .information {
    @media only screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
    margin: 12px 0;
    padding: 8px 12px;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-radius: 7px;
    box-shadow: 1px 1px 2px 0px #15223214;
    .info {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      width: 33%;

      .icone {
        width: 15%;
        margin-right: 16px;
        font-weight: 400;
        color: #28367a;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        color: #515151;
        font-size: 14px;
        margin: 0;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
      }
    }
  }

  footer {
    @media only screen and (max-width: 900px) {
      padding: 0;
    }
    padding: 24px 12px;
    background-color: #fff;
    border-radius: 7px;
    .type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .type__doc {
        width: 24.5%;
        padding: 8px 0;
        text-align: center;
        font-size: 12px;
        background-color: #e9ecef;
        color: #000;
        border-radius: 5px 5px 0 0;
        cursor: pointer;
      }

      .active {
        background-color: #f5f6f8;
        font-weight: 600;
        font-size: 14px;
        position: relative;

        &::after {
          @media only screen and (max-width: 900px) {
            left: 25%;
          }
          content: "";
          width: 40px;
          border-top: 2px solid #28367a;
          position: absolute;
          left: 40%;
          bottom: 7px;
        }
      }
    }

    .liste {
      padding: 8px;
      box-shadow: 1px 1px 2px 0px #15223214;
      background-color: #f5f6f8;
      cursor: pointer;
      top: -2px;
      position: relative;

      p {
        margin: 0;
        text-align: center;
      }

      .client_file,
      .devis_facture {
        border-radius: 7px;
        h2,
        p {
          margin: 0;
          display: flex;
          justify-content: center;
          width: 113px;
        }
        h2 {
          @media only screen and (max-width: 900px) {
            font-size: 12px;
          }
          overflow: hidden;
          font-size: 14px;
          color: #000;
          width: 128px;
        }
        p {
          @media only screen and (max-width: 900px) {
            font-size: 10px;
          }
          font-size: 12px;
          color: #515151;
          .status {
            @media only screen and (max-width: 900px) {
              min-width: 64px;
              max-width: 64px;
            }
            background-color: #ffb300;
            color: #fff;
            min-width: 75px;
            max-width: 75px;
            padding: 4px;
            border-radius: 14px;
          }
          .stat {
            @media only screen and (max-width: 900px) {
              min-width: 64px;
              max-width: 64px;
            }
            background-color: #e4261b;
            color: #fff;
            min-width: 75px;
            max-width: 75px;
            padding: 4px;
            border-radius: 14px;
          }
          .none {
            @media only screen and (max-width: 900px) {
              min-width: 64px;
              max-width: 64px;
            }
            background-color: #e4261b;
            color: #fff;
            min-width: 75px;
            max-width: 75px;
            padding: 4px;
            border-radius: 14px;
          }
        }

        .status {
          @media only screen and (max-width: 900px) {
            min-width: 64px;
            max-width: 64px;
          }
          background-color: #ffb300;
          color: #fff;
          min-width: 75px;
          max-width: 75px;
          padding: 4px;
          border-radius: 14px;
        }
        .stat {
          @media only screen and (max-width: 900px) {
            min-width: 64px;
            max-width: 64px;
          }
          background-color: #e4261b;
          color: #fff;
          min-width: 75px;
          max-width: 75px;
          padding: 4px;
          border-radius: 14px;
        }
        .none {
          @media only screen and (max-width: 900px) {
            min-width: 64px;
            max-width: 64px;
          }
          background-color: #e4261b;
          color: #fff;
          min-width: 75px;
          max-width: 75px;
          padding: 4px;
          border-radius: 14px;
        }
        .description {
          width: 30%;
          text-align: left;
        }
      }

      .devis_facture,
      .client_file {
        @media only screen and (max-width: 900px) {
          padding: 8px;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        background-color: #fff;
        color: #28367a;
        margin-bottom: 6px;

        &:hover {
          background-color: #eef1fd;
        }

        .info {
          text-align: center !important;
          h2,
          p {
            margin: 0;
          }
          h2 {
            @media only screen and (max-width: 900px) {
              font-size: 12px;
            }
            font-size: 14px;
            font-weight: 800;
            color: #000;
          }
          p {
            font-size: 12px;
            color: #515151;
          }
        }

        .action {
          @media only screen and (max-width: 900px) {
            width: 25%;
          }
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 15%;
          div {
            @media only screen and (max-width: 900px) {
              width: 20px;
              height: 20px;
            }
            background-color: #28367a;
            border-radius: 4px;
            width: 24px;
            height: 24px;
            font-size: 12px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
    .agenda {
      padding: 8px;
      background-color: #f5f6f8;

      h1 {
        width: 100%;
        padding: 4px 0;
        text-align: center;
        background-color: #28367a;
        border-radius: 7px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        margin-top: 8px;
      }

      .event {
        padding: 8px 32px;
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 4px;
        h2,
        p {
          margin: 0;
        }
        h2 {
          font-size: 14px;
          font-weight: 800;
          color: #000;
        }
        p {
          font-size: 12px;
          color: #515151;
        }
      }
    }

    .load_doc {
      width: 100%;
      background-color: #f5f6f8;
      min-height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.langues {
  background-color: #fff;
  padding: 8px 0;
  .lang {
    background-color: #f5f6f8;
    display: block;
    text-decoration: none;
    padding: 10px 24px;
    color: #28367a;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 4px;

    &:hover {
      background-color: #e9ecef;
    }
  }
}

.svgtele {
  width: 13.5px;
  height: 15px;
}
.svg {
  height: 22px;
  width: 80%;
  margin-left: 3px;
}

.fa-chevron-right {
  @media only screen and (max-width: 900px) {
    display: none;
  }
}
.error-message {
  font-size: 16px !important;
}
</style>
